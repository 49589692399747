<template>
    <el-scrollbar class="StoreMarketingLayout" :native="false">
<!--    <div class="StoreMarketingLayout">-->
        <div class="headerStep">
            <div class="stepOne">
                <span class="num numActive">1</span>
                <span class="text">创建营销数据</span>
            </div>
            <img v-if="stepIndex == 1" class="arrows" src="./../../assets/images/serviceData/xian.png" alt="">
            <img v-if="stepIndex > 1" class="arrows" src="./../../assets/images/serviceData/blue.png" alt="">
            <div class="stepOne">
                <span :class="stepIndex > 1 ? 'num numActive' : 'num'">2</span>
                <span class="text">添加营销数据</span>
            </div>
            <img v-if="stepIndex < 3" class="arrows" src="./../../assets/images/serviceData/xian.png" alt="">
            <img v-if="stepIndex > 2" class="arrows" src="./../../assets/images/serviceData/blue.png" alt="">
            <div class="stepOne">
                <span :class="stepIndex > 2 ? 'num numActive' : 'num'">3</span>
                <span class="text">添加分值数据</span>
            </div>
            <img v-if="stepIndex < 4" class="arrows" src="./../../assets/images/serviceData/xian.png" alt="">
            <img v-if="stepIndex > 3" class="arrows" src="./../../assets/images/serviceData/blue.png" alt="">
            <div class="stepOne">
                <span :class="stepIndex > 3 ? 'num numActive' : 'num'">4</span>
                <span class="text">创建成功</span>
            </div>
        </div>
        <div class="StoreMarketingForm">
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </div>
<!--    </div>-->
    </el-scrollbar>
</template>

<script>
    export default {
        name: "StoreMarketingAdd",
        data(){
            return {
                stepIndex:1
            }
        },
        watch: {
            $route(route){
                if(route.name == 'operateDataStoreMarketingOne'){
                    this.stepIndex = 1;
                } else if(route.name == 'operateDataStoreMarketingTwo'){
                    this.stepIndex = 2;
                } else if(route.name == 'operateDataStoreMarketingThree'){
                    this.stepIndex = 3
                } else if(route.name == 'operateDataStoreMarketingSucceed'){
                    this.stepIndex = 4
                }
                console.log(53,this.stepActive)
            }
        },
        created() {
            this.stepWatch();
        },
        methods: {
            stepWatch(){
                if(this.$route.name == 'operateDataStoreMarketingOne'){
                    this.stepIndex = 1;
                } else if(this.$route.name == 'operateDataStoreMarketingTwo'){
                    this.stepIndex = 2;
                } else if(this.$route.name == 'operateDataStoreMarketingThree'){
                    this.stepIndex = 3;
                } else if(this.$route.name == 'operateDataStoreMarketingSucceed'){
                    this.stepIndex = 4;
                }
            },
        },
    }
</script>

<style scoped lang="scss">
    .StoreMarketingLayout{
        height: 100%;
        /deep/ .el-scrollbar__wrap {
            overflow-x: hidden;
            .el-scrollbar__view {
                padding: 30px;
            }
        }
        .headerStep{
            margin-top: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            .stepOne{
                display: flex;
                flex-direction: column;
                align-items: center;
                .num{
                    line-height: 1;
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background: #D1D2DB;
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;
                    line-height: 40px;
                }
                .numActive{
                    background: #1122D8;
                }
                .text{
                    line-height: 1;
                    color: #878994;
                    font-size: 14px;
                    font-weight: 500;
                    margin-top: 10px;
                }
            }
            .arrows{
                height: 10px;
                margin-bottom: 20px;
            }
        }
        .StoreMarketingForm{
            margin-top: 76px;
        }
    }
</style>